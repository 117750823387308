import { Dispatch } from '../../util/types';

import * as app from '../app/app';
import * as content from '../content/content';
import * as contract from '../entities/contract';
import * as insurance from '../entities/insurance';
import * as repair from '../entities/repair';
import * as tires from '../entities/tires';
import * as users from '../entities/users';
import * as vehicles from '../entities/vehicles';
import * as sturingen from '../entities/sturingen';

import { setInitialized } from '../auth/auth';
import { setLoading } from '../ui/loading';
import { watchdog } from '../watchdog/watchdog';

const promise: (_: any) => Promise<any> = (dispatch) => {
  return dispatch as unknown as Promise<any>;
};

const success: (_: any) => boolean = (action) => {
  return (action.type as string).endsWith('SUCCESS');
};

const failure: (_: any) => boolean = (action) => {
  return (action.type as string).endsWith('FAILURE');
};

export const loadAll =
  (images = false, vehicleCheck: object = {}) =>
  (dispatch: Dispatch) => {
    dispatch(setLoading(true));

    return Promise.all([
      // Load user
      promise(users.readUser()(dispatch)),

      // Load vehicle
      promise(vehicles.readVehicles()(dispatch)).then((res) => {
        if (failure(res)) dispatch(setLoading(false));
        if (!success(res)) return;

        const loadedVehicles: { [key: string]: any } = res.response.entities.vehicle;
        return Promise.all([
          ...Object.keys(loadedVehicles).map((vId) => {
            const vehicle = loadedVehicles[vId];
            return Promise.all([
              // Load insurance
              promise(insurance.readInsurance(vehicle)(dispatch)).then((res) => {
                if (!images) return;
                if (failure(res)) return;
                if (!success(res)) return;

                const insuranceCardId = res.response.entities.insurance[res.response.result].urlGroeneKaart;

                return Promise.all([
                  promise(insurance.readEUDamageForm(vehicle)(dispatch)),
                  promise(insurance.readInsuranceCard(insuranceCardId, vehicle)(dispatch)),
                ]);
              }),

              // Load contract
              promise(contract.readContract(vehicle)(dispatch)),

              // Load repair options
              promise(repair.readRepair(vehicle)(dispatch)),

              // Load tires
              promise(tires.readTires(vehicle)(dispatch)),

              // Load tire changes
              promise(tires.readTireChange(vehicle)(dispatch)),

              // Load sturingen
              promise(sturingen.readSturingen(vehicle)(dispatch)),
            ]);
          }),
        ]);
      }),

      // ##
      // # CONTENT
      // ##
      // Load FAQ
      promise(images ? content.loadFAQ(true)(dispatch) : false),
      // promise(
      //   docs.loadFile(`${window.location.origin}/faq/index.json`, true)(dispatch)
      // ).then((res) =>
      //   Promise.all(
      //     res.response.map((cat: any) =>
      //       Promise.all(
      //         cat.items.map((item: string) =>
      //           docs.loadFile(
      //             `${window.location.origin}/faq/${item}.json`,
      //             true
      //           )(dispatch)
      //         )
      //       )
      //     )
      //   )
      // ),

      // ##
      // # DELETE OLD CONTENT
      // ##
      promise(vehicles.readVehicleStatus(Object.keys(vehicleCheck) as any)(dispatch)),
    ]).then(
      () => {
        dispatch(setInitialized());
        dispatch(setLoading(false));
        dispatch(app.setAppState({ refresh: new Date(Date.now() - 86400000) }));
      },
      (reason: any) => {
        // Let the watchdog know what is going on
        watchdog({
          logType: 'REDUX',
          message: `${reason.message}\n${reason.stack ? reason.stack.toString() : 'No stacktrace available'}`,
          severity: 6,
          referer: 'redux/loadAll',
        })(dispatch);

        // And finally hide the loading bar, as it will otherwise
        // confuse the users as to what is going on
        dispatch(setLoading(false));
      }
    );
  };
